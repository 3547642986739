import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, SpeedDial, SpeedDialAction, SpeedDialIcon,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { AxiosResponse } from 'axios';
import { CreateNewFolderOutlined, Sync } from '@mui/icons-material';
import { getEntrataTables } from '../../../api/endpoints-api/EndpointsAPI';
import { EntrataEditScheduleModal } from './EntrataEditScheduleModal';
import { EntrataTableData } from './entrata-sync-types';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { EntrataDeleteConfirmModal } from './EntrataDeleteConfirmModal';
import { EntrataTablesGroup } from '../landing-page-types';
import { EntrataTablesDataGrid } from './EntrataTablesDataGrid';
import { getGroupColumns, getGroupRows } from '../landing-page-helpers';
import { EntrataSyncParams } from './entrata-sync-params';

/**
 * Component which allows user to view and manage all Entrata sync jobs.
 * @constructor
 */
export const EntrataSync = function ({
  groups, groupMappings, loadingGroups, openCreateGroupModal, refreshTable, toggleRefresh, updateGroupToDelete,
}: EntrataSyncParams) {
  // sync jobs that populate DataGrid.  each row represents one table being synced from Entrata.
  const [tables, setTables] = useState<EntrataTableData[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  // the current table whose custom schedule is being edited
  const [tableIdBeingEdited, setTableIdBeingEdited] = useState<string>('');
  // current table whose custom schedule is being deleted
  const [tableScheduleBeingDeleted, setTableScheduleBeingDeleted] = useState<string>('');

  const alert = useAlert();

  const closeEditingModal = () => setTableIdBeingEdited('');
  const closeDeletionModal = () => setTableScheduleBeingDeleted('');

  /**
   * Retrieves all relevant data about Entrata sync jobs to populate the DataGrid.
   */
  useEffect(() => {
    // we want to refresh the table whenever the edit modal is being closed, but not when its being opened
    if (tableIdBeingEdited) return;
    setLoadingTableData(true);
    getEntrataTables()
      .then(({ data }: AxiosResponse<EntrataTableData[]>) => {
        const sortedTables = data.sort();
        setTables(sortedTables);
      })
      .catch(() => alert?.alert('Failed to retrieve table data', 'error'))
      .finally(() => setLoadingTableData(false));
  }, [toggleRefresh, tableIdBeingEdited]);

  // Given the table ID being edited, retrieve the relevant table/sync job metadata.
  const tableBeingEdited = tables.find((table) => table.table === tableIdBeingEdited);

  // MUI speed dial actions
  const speedDialActions = [
    { icon: <Sync />, name: 'Refresh table', action: refreshTable },
    { icon: <CreateNewFolderOutlined />, name: 'Create new group', action: openCreateGroupModal },
  ];

  // Generate group rows for Entrata table jobs.  For group mapping purposes, the table's name is considered its ID.
  const rows: EntrataTablesGroup[] = useMemo(() => getGroupRows<EntrataTableData>(
    groupMappings,
    groups,
    tables,
    ({ table }) => table,
  ), [groups, groupMappings, tables]);

  // Generate datagrid columns
  const columns = useMemo(
    () => getGroupColumns<EntrataTableData>(updateGroupToDelete, groups, refreshTable),
    [updateGroupToDelete, groups, refreshTable],
  );

  return (
    <>
      {/* Modal to edit Entrata sync job custom schedule */}
      {tableBeingEdited
          && (
          <EntrataEditScheduleModal
            table={tableBeingEdited}
            closeModal={closeEditingModal}
          />
          )}
      {/* Confirmation Modal to delete Entrata sync job custom schedule */}
      {tableScheduleBeingDeleted
          && (
          <EntrataDeleteConfirmModal
            tableName={tableScheduleBeingDeleted}
            closeModal={closeDeletionModal}
            refreshTable={refreshTable}
          />
          )}
      {/* Sync jobs/tables datagrid */}
      <Box sx={{ width: '100%' }} className="h-[90%]">
        <DataGridPro
          rows={rows}
          columns={columns}
          loading={loadingTableData || loadingGroups}
          getRowId={({ groupName }) => groupName}
          getDetailPanelContent={({ row }) => EntrataTablesDataGrid({
            group: row,
            groups,
            groupMappings,
            setTableIdBeingEdited,
            setTableScheduleBeingDeleted,
            refreshTable,
          })}
          getDetailPanelHeight={() => 'auto'}
          disableRowSelectionOnClick
        />
      </Box>
      {/* Refreshes the data in the datagrid */}
      <div className="flex flex-col justify-center h-[10%] text-blue-500">
        <SpeedDial
          ariaLabel="Actions"
          icon={<SpeedDialIcon />}
          direction="left"
          FabProps={{ sx: { backgroundColor: 'rgb(59 130 246) !important' } }}
        >
          {speedDialActions.map(({ name, icon, action }) => (
            <SpeedDialAction
              key={name}
              icon={icon}
              tooltipTitle={name}
              onClick={action}
            />
          ))}
        </SpeedDial>
      </div>
    </>
  );
};
