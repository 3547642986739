import React, { useState } from 'react';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import {
  FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { db } from '../../../firebase-config';
import { groupMappingsPath, TableGroupMapping } from './landing-page-types';
import { UpdateGroupSelectorParams } from './landing-page-params';

/**
 * Update a table's group by deleting any existing group membership and creating a new membership (if needed).
 * @param newGroup the new group to move the table to
 * @param tableId the id of the table being moved
 * @param groupMappings mappings of tables in groups
 */
const updateTableGroup = async (newGroup: string, tableId: string, groupMappings: TableGroupMapping[]) => {
  // if the table currently belongs to a group, remove it from that group by deleting the relevant mapping doc
  const relevantMapping = groupMappings.find((mapping) => mapping.tableId === tableId);
  if (relevantMapping) await deleteDoc(doc(db, groupMappingsPath, relevantMapping.id));
  // if the user selected the "None" option, then we want to just remove them from their group and not add them to any new one
  if (!newGroup) return;
  // add the user to new group by creating new mapping doc
  const docId = uuid();
  const data: TableGroupMapping = { tableId, groupId: newGroup, id: docId };
  await setDoc(doc(db, groupMappingsPath, docId), data);
};

/**
 * Selector component which allows user to change the given group of a table.
 * @param tableId id of the table or job that we are updating
 * @param refreshTable function to refresh the parent table
 * @param groupMappings mappings of table to group
 * @param groups all groups
 * @param currentGroupId the id of the group that the table is currently in
 * @constructor
 */
export const UpdateGroupSelector = function ({
  tableId, refreshTable, groupMappings, groups, currentGroupId,
}: UpdateGroupSelectorParams) {
  const [selectedGroup, setSelectedGroup] = useState<string>(currentGroupId);

  const alert = useAlert();

  /**
   * Update a given's table membership in a given group.
   * @param event Selector change event
   */
  const updateGroup = (event: SelectChangeEvent) => {
    const newGroup = event.target.value;

    updateTableGroup(newGroup, tableId, groupMappings)
      .then(() => {
        // we set this state so that the value populates immediately, even though we refresh the table right after
        setSelectedGroup(newGroup);
        alert?.alert('Successfully updated group', 'success');
        refreshTable();
      })
      .catch(() => {
        alert?.alert('Failed to update group', 'error');
      });
  };

  return (
    <FormControl fullWidth>
      <Select
        value={selectedGroup}
        placeholder="Group"
        onChange={updateGroup}
        variant="standard"
      >
        <MenuItem className="italic" value="">None</MenuItem>
        {groups.map((group) => <MenuItem value={group.id}>{group.name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};
