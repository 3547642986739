import { EntrataSchedules } from './entrata-sync-types';
import { daysOfWeek } from '../google-sheet-sync/manage-job-modal/SyncFrequencyOptions';

/**
 * Map of eligible frequencies to static cron expressions.
 */
const cronMapping: { [key: string]: string } = {
  [EntrataSchedules.EVERY_HOUR_EVERY_DAY]: '0 * * * *',
  [EntrataSchedules.EVERY_HOUR_WEEKDAYS]: '0 * * * 1-5',
};

/**
 * Given our user-inputted data, generate the appropriate cron expression.
 * @param primaryFrequency our primary frequency
 * @param weekday the day of the week (if using a Weekly frequency)
 * @param dayOfMonth the day of the month (if using a Monthly frequency)
 */
export const generateSchedule = (primaryFrequency: string, weekday: string, dayOfMonth: string) => {
  switch (primaryFrequency) {
    case EntrataSchedules.EVERY_HOUR_EVERY_DAY:
      return cronMapping[EntrataSchedules.EVERY_HOUR_EVERY_DAY];
    case EntrataSchedules.EVERY_HOUR_WEEKDAYS:
      return cronMapping[EntrataSchedules.EVERY_HOUR_WEEKDAYS];
    case EntrataSchedules.WEEKLY: {
      // find the index of the day of the week - cron expressions are 0-indexed for day of week
      const dayIndex = daysOfWeek.findIndex((item) => item === weekday);
      if (dayIndex === -1) throw new Error('Day not found in week');
      return `0 3 * * ${dayIndex}`;
    }
    case EntrataSchedules.MONTHLY:
      return `0 3 ${dayOfMonth} * *`;
    default:
      throw new Error('Sync frequency invalid');
  }
};

/**
 * Given a cron expression, reverse engineer the primary frequency.
 * @param cron cron expression to parse
 */
const getPrimaryFrequency = (cron: string) => {
  // check if the cron expression matches a static mapped expression and return the match if so
  const staticFrequency = Object.keys(cronMapping).find((key) => cronMapping[key] === cron);
  if (staticFrequency) return staticFrequency;
  // otherwise, check if the cron expression matches a Weekly schedule e.g. '0 3 * * [0-6]'
  if (/^0 3 \* \* [0-6]$/.test(cron)) return EntrataSchedules.WEEKLY;
  // If not, check if it matches a monthly schedule, e.g. '0 3 [0-28] * *'
  if (/^0 3 (0|[1-9]|1[0-9]|2[0-8]) \* \*$/.test(cron)) return EntrataSchedules.MONTHLY;
  throw new Error('Cron expression did not match any predefined pattern');
};

/**
 * Given a cron expression, reverse engineer the schedule configuration including primary frequency and
 * day of week/month (if applicable).
 * @param schedule the cron expression to parse
 */
export const getFrequencyFromCron = (schedule: string) => {
  // cron expressions are formatted like '* * * * *' so we split by space
  const [, , dayOfMonth, , dayOfWeek] = schedule.split(' ');
  const primaryFrequency = getPrimaryFrequency(schedule);
  // if day of week is not '*' then use it retrieve week day name
  const parsedDayOfWeek = parseInt(dayOfWeek, 10) ? daysOfWeek[parseInt(dayOfWeek, 10)] : '';
  const parsedDayOfMonth = parseInt(dayOfMonth, 10) ? parseInt(dayOfMonth, 10).toString() : '';
  return [primaryFrequency, parsedDayOfWeek, parsedDayOfMonth];
};
