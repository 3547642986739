import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
  Box, Chip, IconButton, Skeleton,
} from '@mui/material';
import React from 'react';
import {
  Delete, Edit, OpenInNew,
} from '@mui/icons-material';
import cronstrue from 'cronstrue';
import dayjs from 'dayjs';
import {
  JobRunHistory, JobRunStatus, SheetSyncJob, WriteDisposition, UpdateGroupInfo,
} from '../../landing-page-types';
import { JobsDataGridParams } from '../../landing-page-params';
import { ManualSyncButton } from './ManualSyncButton';
import { getLastRun, getShortenedTableId } from '../../../../api/landing-page-api/LandingPageAPI';
import { UpdateGroupSelector } from '../../UpdateGroupSelector';

/**
 * Generate columns for jobs datagrid.
 * @param updateDeleteModalJobId updates the delete job modal status
 * @param updateEditModalJobId update the edit job modal status
 * @param interactive whether to show interactive actions on the datagrid
 * @param jobRunHistory job run history for all jobs
 * @param updateGroupInfo data which allows user to update the group of a given job (this is conditionally passed)
 */
export const generateColumns = (
  updateDeleteModalJobId: (id: string) => void,
  updateEditModalJobId: (id: string) => void,
  interactive: boolean,
  jobRunHistory: JobRunHistory[],
  updateGroupInfo: UpdateGroupInfo | undefined,
): GridColDef<SheetSyncJob>[] => {
  const lastJobRunNotFoundText = "Hasn't run yet";
  const coreColumns: GridColDef<SheetSyncJob>[] = [
    {
      field: 'jobName',
      headerName: 'Job Name',
      flex: 1,
    },
    {
      field: 'sheetName',
      headerName: 'Sheet Name',
      flex: 1,
    },
    {
      field: 'tableId',
      headerName: 'Table ID',
      flex: 1,
      // shortens table ID for readability
      valueGetter: ({ row }) => getShortenedTableId(row.tableId),
    },
    {
      field: 'refreshCron',
      headerName: 'Sync Schedule',
      flex: 1,
      // displays cron as a more readable, descriptive string
      valueGetter: ({ row }) => (
        row.refreshCron ? cronstrue.toString(row.refreshCron) : 'Manual'
      ),
    },
    {
      field: 'writeMethod',
      headerName: 'Upload Method',
      flex: 1,
      // gets more readable version of write disposition
      valueGetter: ({ row }) => (row.writeMethod === WriteDisposition.APPEND ? 'Append' : 'Overwrite'),
    },
    {
      field: 'uuidColumn',
      headerName: 'ID Column',
      flex: 1,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      valueGetter: ({ row }) => (row.shared ? 'Shared' : 'You'),
    },

  ];
  const interactiveColumns: GridColDef<SheetSyncJob>[] = updateGroupInfo ? [
    {
      field: 'lastRunStatus',
      headerName: 'Last Run Status',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      renderCell: ({ row }) => {
        const lastRun = getLastRun(jobRunHistory, row.jobId);
        if (!lastRun) return (<div>{lastJobRunNotFoundText}</div>);
        const color = lastRun.runStatus === JobRunStatus.SUCCESS ? 'bg-green-300' : 'bg-red-300';
        const style = `${color} mx-auto text-gray-800`;
        return (<Chip className={style} label={lastRun.runStatus} />);
      },
    },
    {
      field: 'lastRunTimestamp',
      headerName: 'Last Run Time',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      valueGetter: ({ row }) => {
        const lastRun = getLastRun(jobRunHistory, row.jobId);
        if (!lastRun) return lastJobRunNotFoundText;
        return dayjs.unix(lastRun.runTimestamp.seconds).format('M/DD/YY h:mma');
      },
    },
    {
      field: 'editJob',
      headerName: 'Edit',
      headerAlign: 'center',
      flex: 0.5,
      // opens edit job modal (manageJobModal} in edit mode)
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateEditModalJobId(row.jobId)}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      field: 'deleteJob',
      headerName: 'Delete',
      headerAlign: 'center',
      flex: 0.5,
      // opens delete job modal
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateDeleteModalJobId(row.jobId)}
        >
          <Delete />
        </IconButton>
      ),
    },
    {
      field: 'manualSync',
      headerName: 'Sync',
      headerAlign: 'center',
      flex: 0.5,
      // manually runs a sync for the given job
      renderCell: ({ row }) => ManualSyncButton(row.jobId),
    },
    {
      field: 'workbookLink',
      headerName: 'Link',
      headerAlign: 'center',
      flex: 0.5,
      // displays a link to the related workbook of a given job
      renderCell: ({ row }) => (
        <IconButton
          className="mx-auto"
          onClick={() => { window.open(row.workbookUrl, '_blank'); }}
        >
          <OpenInNew className="w-4" />
        </IconButton>
      ),
    },
    {
      headerName: 'Group',
      field: 'group',
      width: 150,
      renderCell: ({ row }) => (
        <UpdateGroupSelector
          tableId={row.jobId}
          refreshTable={updateGroupInfo.refreshTable}
          groupMappings={updateGroupInfo.groupMappings}
          groups={updateGroupInfo.groups}
          currentGroupId={updateGroupInfo.currentGroupId}
        />
      ),
    },
  ] : [];
  return interactive ? [...coreColumns, ...interactiveColumns] : coreColumns;
};

/**
 * Shows Google Sheets sync jobs.
 * @param updateDeleteModalJobId updates the delete job modal status
 * @param updateEditModalJobId update the edit job modal status
 * @param jobRows the jobs in the datagrid
 * @param interactive whether to show interactive actions on the datagrid
 * @param jobsLoading whether the jobs are loading
 * @param jobRunHistory job run history for all jobs
 * @param updateGroupInfo data which allows user to update the group of a given job (this is conditionally passed)
 * @constructor
 */
export const JobsDataGrid = function ({
  updateDeleteModalJobId,
  updateEditModalJobId,
  jobRows,
  interactive,
  jobsLoading,
  jobRunHistory,
  updateGroupInfo,
}: JobsDataGridParams) {
  const columns = generateColumns(
    updateDeleteModalJobId,
    updateEditModalJobId,
    interactive,
    jobRunHistory,
    updateGroupInfo,
  );

  const containerStyle = { height: '100%', width: '100%' };

  if (jobsLoading) return <Skeleton sx={containerStyle} variant="rectangular" />;

  return (
    <Box sx={containerStyle}>
      <DataGridPro
        rows={jobRows}
        columns={columns}
        getRowId={({ jobId }: SheetSyncJob) => jobId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
