import React, { useState } from 'react';
import {
  Dialog, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EntrataSchedules, TableScheduleType } from './entrata-sync-types';
import { createEntrataScheduler, deleteEntrataScheduler } from '../../../api/endpoints-api/EndpointsAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { EntrataEditScheduleModalParams } from './entrata-sync-params';
import { generateSchedule, getFrequencyFromCron } from './entrata-sync-helpers';
import { daysOfMonth, daysOfWeek } from '../google-sheet-sync/manage-job-modal/SyncFrequencyOptions';

/**
 * Generate the initial values for the schedule editor.
 * @param customSchedule existing custom schedule (if there is one)
 */
const generateInitialEditorValues = (customSchedule: string | undefined) => {
  const defaultInits = ['', daysOfWeek[0], '1'];
  return customSchedule ? getFrequencyFromCron(customSchedule) : defaultInits;
};

/**
 * Modal used to edit Entrata sync job custom schedule.
 * @param table the table name of the sync job
 * @param closeModal function to close this modal
 * @constructor
 */
export const EntrataEditScheduleModal = function ({ table, closeModal }: EntrataEditScheduleModalParams) {
  // if we are editing (rather than creating) a custom schedule, we will need to pre-populate the inputs
  // with data parsed from existing schedule
  const customSchedule = table.schedule.type === TableScheduleType.CUSTOM
    ? table.schedule.schedule.schedule
    : undefined;
  const [initPrimary, initWeekDay, initMonthDay] = generateInitialEditorValues(customSchedule);

  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  // the primary frequency - e.g. a static cron like 'Every hour every day' or a dynamic cron like 'Weekly'
  const [primaryFrequency, setPrimaryFrequency] = useState<string>(initPrimary);
  // the day of the week (if using Weekly frequency)
  const [weeklyFrequency, setWeeklyFrequency] = useState<string>(initWeekDay);
  // the day of the month (if using Monthly frequency)
  const [monthlyFrequency, setMonthlyFrequency] = useState<string>(initMonthDay);

  const alert = useAlert();

  /**
   * Creates a Cloud Scheduler for a given sync job.
   */
  const createSchedule = () => {
    const schedule = generateSchedule(primaryFrequency, weeklyFrequency, monthlyFrequency);
    setLoadingCreate(true);
    createEntrataScheduler({ tableName: table.table, schedule })
      .then(() => {
        alert?.alert('Created schedule successfully', 'success');
        closeModal();
      })
      .catch(() => { alert?.alert('Failed to create schedule', 'error'); })
      .finally(() => { setLoadingCreate(false); });
  };

  /**
   * Overwrites (deletes and then re-creates) a Cloud Scheduler for a given sync job.
   */
  const overwriteSchedule = async () => {
    setLoadingCreate(true);
    try {
      const schedule = generateSchedule(primaryFrequency, weeklyFrequency, monthlyFrequency);
      await deleteEntrataScheduler({ tableName: table.table });
      await createEntrataScheduler({ tableName: table.table, schedule });
      alert?.alert('Overwrote schedule successfully', 'success');
      closeModal();
    } catch {
      alert?.alert('Failed to overwrite scheduler', 'error');
    } finally {
      setLoadingCreate(false);
    }
  };

  // represents whether any of the inputs have been changed from their initial values
  const inputDirty = initPrimary !== primaryFrequency
      || initWeekDay !== weeklyFrequency
      || initMonthDay !== monthlyFrequency;

  // we should disable the create scheduler button if we are missing critical data or if the schedule is unchanged from
  // its starting value (i.e. when overwriting)
  const disableCreate = !primaryFrequency
      || (primaryFrequency === EntrataSchedules.WEEKLY && !weeklyFrequency)
      || (primaryFrequency === EntrataSchedules.MONTHLY && !monthlyFrequency) || !inputDirty;

  return (
    <Dialog
      open
      onClose={closeModal}
      className="w-[100vw] bg-transparent"
      maxWidth="xl"
    >
      {/* Modal itself  */}
      <div className="relative z-40 p-10 bg-white rounded-lg flex flex-col gap-y-8
        items-center justify-center"
      >
        <div className="text-center">
          Edit Custom Schedule for &nbsp;
          <b>{table.table}</b>
        </div>
        <div className="mb-4 flex flex-row items-center gap-x-6">
          {/* Primary frequency selector */}
          <FormControl className="w-44">
            <InputLabel>Sync Frequency</InputLabel>
            <Select
              value={primaryFrequency}
              label="Sync Frequency"
              variant="standard"
              onChange={(e) => { setPrimaryFrequency(e.target.value); }}
            >
              {Object.values(EntrataSchedules).map(
                (option) => (<MenuItem key={option} value={option}>{option}</MenuItem>),
              )}
            </Select>
          </FormControl>
          {/* Weekly frequency selector */}
          {primaryFrequency === EntrataSchedules.WEEKLY && (
          <FormControl className="w-44">
            <InputLabel>Day of Week</InputLabel>
            <Select
              value={weeklyFrequency}
              label="Day of Week"
              variant="standard"
              onChange={(e) => { setWeeklyFrequency(e.target.value); }}
            >
              {daysOfWeek.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
            </Select>
          </FormControl>
          )}
          {/* Monthly frequency selector */}
          {primaryFrequency === EntrataSchedules.MONTHLY && (
          <FormControl className="w-44">
            <InputLabel>Day of Month</InputLabel>
            <Select
              value={monthlyFrequency}
              label="Day of Month"
              variant="standard"
              onChange={(e) => { setMonthlyFrequency(e.target.value); }}
            >
              {daysOfMonth.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
            </Select>
          </FormControl>
          )}
        </div>
        <LoadingButton
          variant="contained"
          className="bg-blue-500"
          onClick={customSchedule ? overwriteSchedule : createSchedule}
          loading={loadingCreate}
          disabled={disableCreate}
        >
          {customSchedule ? 'Overwrite schedule' : 'Create new schedule'}
        </LoadingButton>
      </div>
    </Dialog>
  );
};
