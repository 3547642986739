import React, { PropsWithChildren } from 'react';
import { NavigationBar } from '../../navigation/Navigation';
import { getNavigationItems } from '../../api';
import { PageType } from '../../api/navigation-api/NavigationTypes';
import { UserRole } from '../../pages/admin-page/admin-types';

interface MyContainerProps {
  focusedPage?: PageType
  className?: string
  userRoles: UserRole[] | undefined | null
}

/**
 * A wrapper method for placing props inside a container.
 * Incorporates the navigation bar into everything that uses the navbar.
 * Checks if a user is logged in, if not, redirects them to the landing page.
 * @param focusedPage The page that currently should have the focus
 * @param children The elements inside the container.
 * @param className Additional styling
 * @param userRoles current user perms
 * @constructor
 */
export const SheetSyncContainer = function (
  {
    children, className, focusedPage, userRoles,
  }: PropsWithChildren<MyContainerProps>,
) {
  return (
    <div className={className}>
      <NavigationBar
        userRoles={userRoles}
        focusedPage={focusedPage}
        navigationItems={getNavigationItems()}
      />
      {children}
    </div>
  );
};
