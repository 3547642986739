import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { TableGroupActionsParams } from './landing-page-params';

// id used for the Unsorted group (contains all ungrouped jobs)
export const UNSORTED_GROUP_ID = 'unsorted';

/**
 * Actions for table group.
 * @param group the group we are acting on
 * @param updateGroupToDelete opens the Delete Group Confirmation modal for a given group
 * @constructor
 */
export const TableGroupActions = function ({ group, updateGroupToDelete }: TableGroupActionsParams) {
  // don't allow users to delete groups with tables still in them
  if (group.tables.length || group.groupId === UNSORTED_GROUP_ID) return null;

  return <IconButton onClick={() => updateGroupToDelete(group)}><Delete /></IconButton>;
};
