import { LoadingButton } from '@mui/lab';
import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { DeleteGroupConfirmModalParams } from './landing-page-params';
import { groupDefinitionsPath } from './landing-page-types';

/**
 * Confirmation modal that pops up when trying to delete a group.
 * @param group the group to delete
 * @param refreshTable function to refresh datagrid
 * @param closeModal closes the modal
 * @constructor
 */
export const DeleteGroupConfirmModal = function ({ group, refreshTable, closeModal }: DeleteGroupConfirmModalParams) {
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  /**
   * Deletes a group given the group id.
   */
  const deleteGroup = () => {
    setLoading(true);
    const docRef = doc(db, groupDefinitionsPath, group.groupId);
    deleteDoc(docRef)
      .then(() => {
        alert?.alert('Successfully deleted group', 'success');
        refreshTable();
        closeModal();
      })
      .catch(() => { alert?.alert('Failed to delete group', 'error'); })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      maxWidth="xl"
    >
      <div className="relative z-40 w-full bg-white rounded-lg py-4 px-8 flex flex-col gap-y-4
    items-center justify-center"
      >
        <div>
          Are you sure you want to delete the group &nbsp;
          <b>{group.groupName}</b>
          ?
        </div>
        <LoadingButton
          onClick={deleteGroup}
          variant="contained"
          className="bg-blue-500"
          loading={loading}
        >
          Delete
        </LoadingButton>
      </div>
    </Dialog>
  );
};
