import { LoadingButton } from '@mui/lab';
import { Dialog, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  doc, getDocs, setDoc, collection,
} from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import { DataSource, TableGroupDoc, groupDefinitionsPath } from './landing-page-types';
import { db } from '../../../firebase-config';
import { useAlert } from '../../api/alert-api/AlertAPI';

/**
 * Modal to create a group.
 * @param closeModal close the modal
 * @param source data source to create the group for
 * @constructor
 */
export const CreateGroupModal = function ({ closeModal, source }: { closeModal: () => void, source: DataSource }) {
  // the name of the new group
  const [groupName, setGroupName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  // all existing groups
  const [existingGroups, setExistingGroups] = useState<TableGroupDoc[]>([]);

  const alert = useAlert();

  /**
   * Get all existing groups (to check for any name conflicts).
   */
  useEffect(() => {
    getDocs(collection(db, groupDefinitionsPath))
      .then(({ docs }) => {
        const groups = docs.map((groupDoc) => groupDoc.data()) as TableGroupDoc[];
        setExistingGroups(groups);
      })
      .catch(() => { alert?.alert('Failed to retrieve existing groups', 'error'); });
  }, []);

  /**
   * Create a new group.
   */
  const createGroup = () => {
    const docId = uuid();
    setLoading(true);
    const payload = { source, id: docId, name: groupName };
    setDoc(doc(db, groupDefinitionsPath, docId), payload)
      .then(() => {
        alert?.alert('Successfully created group', 'success');
        closeModal();
      })
      .catch(() => alert?.alert('Failed to create group', 'error'))
      .finally(() => setLoading(false));
  };

  // Validate that the group name does not already exist (for the same data source, for a different source is ok).
  const disable = !groupName || !!existingGroups.find((group) => group.name === groupName && group.source === source);

  return (
    <Dialog
      open
      onClose={closeModal}
      maxWidth="xl"
    >
      <div className="relative z-40 w-full bg-white rounded-lg py-4 px-8 flex flex-col gap-y-4
    items-center justify-center"
      >
        <div>Create new group</div>
        <TextField
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="w-96"
        />
        <LoadingButton
          onClick={createGroup}
          variant="contained"
          className="bg-blue-500"
          disabled={disable}
          loading={loading}
        >
          Create
        </LoadingButton>
      </div>
    </Dialog>
  );
};
