import React from 'react';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { CustomScheduleActionsParams } from './entrata-sync-params';
import { TableScheduleType } from './entrata-sync-types';

/**
 * Actions for Custom Scheduler for an Entrata Sync Job.
 * @param row row of datagrid (represents sync job)
 * @param setTableIdBeingEdited function to set which table custom schedule is being edited
 * @param setTableScheduleBeingDeleted function to set which table custom schedule is being deleted
 * @constructor
 */
export const CustomScheduleActions = function ({
  row,
  setTableIdBeingEdited,
  setTableScheduleBeingDeleted,
}: CustomScheduleActionsParams) {
  const { table, schedule } = row;
  const customSchedule = schedule.type === TableScheduleType.CUSTOM;

  return (
    <div className="flex flex-row gap-x-2">
      {/*  Edit custom schedule button */}
      <IconButton
        onClick={() => { setTableIdBeingEdited(table); }}
        className="mx-auto"
      >
        <Edit />
      </IconButton>
      {/*  Delete custom schedule button  */}
      {customSchedule && (
        <IconButton
          color="error"
          onClick={() => setTableScheduleBeingDeleted(table)}
          className="mx-auto"
        >
          <Delete />
        </IconButton>
      )}
    </div>
  );
};
