import { IconButton, TextField } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import React from 'react';
import { WorkbookUrlInputParams } from '../../landing-page-params';

export const WorkbookUrlInput = function ({
  syncJob, updateSyncJob, existingSyncJob, sheetOptionsRetrieved,
}: WorkbookUrlInputParams) {
  return (
    <div className="flex flex-row">
      <TextField
        label="Workbook URL"
        className="w-full"
        variant="outlined"
        onChange={({ target }) => updateSyncJob({ ...syncJob, workbookUrl: target.value })}
        value={syncJob.workbookUrl}
        disabled={!!existingSyncJob || sheetOptionsRetrieved}
      />
      {existingSyncJob && (
        <IconButton
          className="ml-4 h-6 w-6 my-auto"
          onClick={() => window.open(existingSyncJob.workbookUrl, '_blank')}
        >
          <OpenInNew className="w-4" />
        </IconButton>
      )}
    </div>
  );
};
