import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Box } from '@mui/material';
import {
  DataGridPro, GridAlignment, GridColDef, GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { SheetSyncContainer } from '../../helper';
import { PageType } from '../../api/navigation-api/NavigationTypes';
import { UserRecord, UserRole, UserRoles } from './admin-types';
import { getAppUsers } from '../../api/endpoints-api/EndpointsAPI';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { useUser } from '../../api';
import { UserRoleCheckbox } from './UserRoleCheckbox';

/**
 * Generate columns for User Permissions datagrid.
 * @param toggleRefresh refreshes the user permissions table
 */
const getColumns = (toggleRefresh: () => void): GridColDef<UserRecord>[] => [
  {
    field: 'displayName',
    flex: 1,
    headerName: 'Name',
  },
  {
    field: 'email',
    flex: 1,
    headerName: 'Email',
  },
  //   Generate a checkbox component/cell for each role that a user can have
  ...(Object.values(UserRoles).map((role) => ({
    field: role,
    flex: 1,
    headerName: role,
    type: 'actions',
    headerAlign: 'center' as GridAlignment,
    renderCell: (
      params: GridRenderCellParams<UserRecord>,
    ) => <UserRoleCheckbox user={params.row} role={role} toggleRefresh={toggleRefresh} />,
  }))),
];

/**
 * Admin page where admin can modify user permissions.
 * @param userRoles current user's roles
 */
export const AdminPage = function ({ userRoles }:{ userRoles: UserRole[] | undefined | null }) {
  // all users of the app
  const [users, setUsers] = useState<UserRecord[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);

  const alert = useAlert();
  const user = useUser();

  /**
   * Retrieve app user data.
   */
  useEffect(() => {
    setLoadingUsers(true);
    getAppUsers()
      .then(({ data }: AxiosResponse<UserRecord[]>) => {
        // we want to remove the current user from the users list so they can't modify themselves
        const relevantUsers = data.filter(({ uid }) => uid !== user?.uid);
        setUsers(relevantUsers);
      })
      .catch(() => alert?.alert('Failed to get users', 'error'))
      .finally(() => setLoadingUsers(false));
  }, [refresh]);

  // Refreshes the users table
  const toggleRefresh = () => setRefresh(!refresh);

  const columns = useMemo(() => getColumns(toggleRefresh), [toggleRefresh]);

  return (
    <SheetSyncContainer
      focusedPage={PageType.Admin}
      className="bg-gray-50 w-[100vw]"
      userRoles={userRoles}
    >
      <div className="ml-44 font-inter flex flex-col p-6 h-[100vh]">
        <div className="text-5xl font-semibold h-[10%]">Admin Settings</div>
        {/* Datagrid used to modify user permissions */}
        <Box sx={{ height: '90%', width: '100%' }}>
          <DataGridPro
            loading={loadingUsers}
            rows={users}
            getRowId={({ uid }) => uid}
            columns={columns}
          />
        </Box>
      </div>
    </SheetSyncContainer>
  );
};
