import { Skeleton, TextField } from '@mui/material';
import React from 'react';
import { CommentsInputParams } from '../../landing-page-params';

export const CommentsInput = function ({ syncJob, updateSyncJob, loadingSheetData }: CommentsInputParams) {
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-40 mt-8" />;
  return (
    <TextField
      label="Comments"
      multiline
      rows={5}
      variant="outlined"
      className="mt-8 w-full mx-auto"
      value={syncJob.comments}
      onChange={({ target }) => updateSyncJob({ ...syncJob, comments: target.value })}
    />
  );
};
