import { collection, getDocs } from 'firebase/firestore';
import { GridColDef } from '@mui/x-data-grid-pro';
import { db } from '../../../firebase-config';
import {
  DataSource, groupDefinitionsPath, groupMappingsPath,
  TableGroupDoc, TableGroupMapping, TablesGroup,
} from './landing-page-types';
import { TableGroupActions, UNSORTED_GROUP_ID } from './TableGroupActions';
import { UpdateGroupNameInput } from './UpdateGroupNameInput';

/**
 * Gets all groups data from Firestore, filtered by data source
 * @param source the data source to filter by
 */
export const getGroupsData = async (source: DataSource) => {
  // Get all groups docs
  const groupsRes = await getDocs(collection(db, groupDefinitionsPath));
  const groups = groupsRes.docs.map((groupDoc) => groupDoc.data()) as TableGroupDoc[];
  // Filter docs by data source
  const groupDocs = groups.filter((doc) => doc.source === source);
  const relevantGroupIds = groupDocs.map(({ id }) => id);
  // Get all group mappings docs
  const mappingsRes = await getDocs(collection(db, groupMappingsPath));
  const mappingsDocs = mappingsRes.docs.map((groupDoc) => groupDoc.data()) as TableGroupMapping[];
  // Filter mappings for mappings relevant to data source
  const mappings = mappingsDocs.filter(({ groupId }) => relevantGroupIds.includes(groupId));
  return { groupDocs, mappings };
};

/**
 * Generates grouped rows for parent DataGrids.  These rows can be expanded to show their children jobs/tables.
 * @param groupMappings mappings of tables to groups
 * @param groups groups data
 * @param tables all tables/jobs
 * @param getTableId function to get what will be used as the ID of the table/job for mapping purposes
 */
export const getGroupRows = <T>(
  groupMappings: TableGroupMapping[],
  groups: TableGroupDoc[],
  tables: T[],
  getTableId: (table: T) => string,
) => {
  // gets IDs for every table which is mapped to a group
  const mappedTables = groupMappings.map(({ tableId }) => tableId);
  // gets IDs for every table which is not mapped to a group
  const unmappedTables = tables.filter((table) => !mappedTables.includes(getTableId(table)));
  // create a default group for all unmapped tables
  const unsorted = {
    groupName: 'Unsorted',
    groupId: UNSORTED_GROUP_ID,
    tables: unmappedTables,
  };
  // for every group, coalesce all tables/jobs which are mapped to that group and return the result
  const mappedGroups = groups.map((group) => {
    const mappings = groupMappings.filter(({ groupId }) => groupId === group.id);
    const tableIds = mappings.map(({ tableId }) => tableId);
    const tablesInThisGroup = tables.filter((table) => tableIds.includes(getTableId(table)));
    return {
      tables: tablesInThisGroup,
      groupName: group.name,
      groupId: group.id,
    };
  });
  // only include the "unsorted" group if there are any unsorted tables
  return (unsorted.tables.length ? [...mappedGroups, unsorted] : mappedGroups) as TablesGroup<T>[];
};

/**
 * Get columns for groups datagrid.
 * @param updateGroupToDelete function to control the confirmation modal when deleting a group
 * @param groups relevant groups
 * @param refreshTable refreshes datagrid
 */
export const getGroupColumns = <T>(
  updateGroupToDelete: (group: TablesGroup) => void,
  groups: TableGroupDoc[],
  refreshTable: () => void,
): GridColDef<TablesGroup<T>>[] => [
    {
      headerName: 'Group',
      field: 'group',
      valueGetter: ({ row }) => row.groupName,
      flex: 1,
      renderCell: ({ row }) => UpdateGroupNameInput({ group: row, groups, refreshTable }),
    }, {
      headerName: 'Number of Tables',
      field: 'tableCount',
      align: 'right',
      valueGetter: ({ row }) => row.tables.length,
      width: 150,
    }, {
      headerName: 'Group Actions',
      field: 'actions',
      renderCell: ({ row }) => TableGroupActions({ group: row, updateGroupToDelete }),
      width: 150,
    }];
