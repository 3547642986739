import React, { Fragment, useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon, QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import {
  navigateTo, signOutHandler, useUser,
} from '../api';
import WestlandLogo from '../../assets/westland-logos/westland-only-logo.svg';
import {
  NavigationItem, PageType,
} from '../api/navigation-api/NavigationTypes';
import { UserRole, UserRoles } from '../pages/admin-page/admin-types';

interface NavigationBarProps {
  /**
     * The currently active and selected page
     */
  focusedPage?: PageType,
  /**
     * The list NavigationItem routes that the user can navigate to.
     */
  navigationItems: NavigationItem[],
  /**
   * Current user permissions.
   */
  userRoles: UserRole[] | undefined | null
  /**
     * The user data associated with the logged in user.
     */
  user?: User | null
}

// className constructor for navigation items
const classNames = function (...classes: string[]) {
  return classes.filter(Boolean).join(' ');
};

// User dropdown menu actions
const userActions = [
  { name: 'Sign out', onClick: signOutHandler },
];

/**
 * Navigation bar page link.
 * @param item navigationItem that the link info is being extracted from
 * @param focusedPage currently focused page that the user is on
 * @param disabled whether the item should be disabled due to lack of permissions
 */
export const NavigationBarPageLink = function ({ item, focusedPage, disabled }:{
  item: NavigationItem, focusedPage: PageType | undefined, disabled: boolean
}) {
  return (
    <Link
      key={item.name}
      to={item.href}
      className={classNames(
        focusedPage === item.id ? 'bg-slate-100 text-black'
          : 'text-gray-700 hover:bg-slate-100 hover:text-gray-600',
        `group flex items-center px-2 py-2 text-sm font-normal rounded-md
         ${disabled ? 'pointer-events-none text-gray-400' : ''}`,
      )}
    >
      <item.icon
        className={classNames(
          focusedPage === item.id ? 'text-black' : 'text-black group-hover:text-gray-800',
          `mr-3 flex-shrink-0 h-6 w-6 ${disabled ? 'text-gray-400' : ''}`,
        )}
        aria-hidden="true"
      />
      <div className="hidden xl:block pr-2">{item.name}</div>
    </Link>
  );
};

/** *
 * The Navigation component is the grouping of different routes
 * throughout the application.
 * Utilizes the navigateTo call from the NavigationAPI to process routes
 */
export const NavigationBar = function (
  {
    userRoles,
    focusedPage,
    navigationItems,
    user = useUser(),
  }: NavigationBarProps,
) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const tangoUrl = 'https://app.tango.us/app/workflow/Step-by-step-instructions-for-'
      + 'syncing-a-new-sheet-and-setting-up-a-sync-job-f4dcd9d537a34b82981b55afb095a1f8';

  // A user is considered an Admin if they have the "Admin" role
  const isAdmin = userRoles?.includes(UserRoles.ADMIN);

  /**
   * When the page first loads, we want to redirect the user to the first tab that they have access to.
   */
  useEffect(() => {
    // if there is already a focused page (i.e. it's not the initial load), then don't redirect
    if (!userRoles || focusedPage) return;
    const defaultRoute = navigationItems.find(({ name }) => userRoles.includes(name as UserRole));
    if (!defaultRoute) return;
    navigateTo(defaultRoute.href);
  }, [userRoles]);

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className={`ml-1 flex h-10 w-10 items-center justify-center rounded-full 
                      focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 h-8 w-auto items-center px-4">
                    <img
                      id="img-mobile"
                      className=""
                      src={WestlandLogo}
                      alt="Westland Logo"
                    />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigationItems.map((item) => (
                      <button
                        type="button"
                        key={item.name}
                        onClick={(() => navigateTo(item.href))}
                        className={classNames(
                          focusedPage === item.id ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                        )}
                      >
                        <item.icon
                          className={classNames(
                            focusedPage === item.id ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                  <a href="/#" className="group block flex-shrink-0" onClick={signOutHandler}>
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={user?.photoURL || ''}
                          alt="Your profile"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">{user?.displayName || 'User Profile Name'}</p>
                        <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">Sign out</p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="w-[60px] min-w-[60px] fixed inset-y-0 flex xl:w-fit flex-col z-40
      drop-shadow-sm xl:min-w-[140px]"
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-white border-r-2 border-gray-200">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4 mt-4">
            <div className="flex flex-shrink-0 items-center px-4" />
            <img
              id="img-desktop"
              className="h-10 w-auto"
              src={WestlandLogo}
              alt="Westland Logo"
            />
            <nav className="mt-5 flex-1 space-y-1 px-2 mt-10">
              {
                navigationItems.map((item) => {
                  // hide any admin-designated tabs if the user has no access
                  if (item.adminRestricted && !isAdmin) return null;
                  // for other tabs the user doesn't have access to, leave them visible but disabled
                  const disabled = !userRoles?.find((role) => role === item.name);
                  return (
                    <NavigationBarPageLink
                      key={item.name}
                      item={item}
                      focusedPage={focusedPage}
                      disabled={disabled}
                    />
                  );
                })
              }
            </nav>
          </div>
          {/* User icon/actions */}
          <Menu as="div" className="mx-auto mb-10 ml-12">
            <div>
              <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none
                    focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-12 w-12 rounded-full"
                  src={user?.photoURL || ''}
                  alt=""
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="fixed left-28 bottom-[6.5rem] z-10 mt-2 w-48 origin-right rounded-md bg-white
               py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {userActions.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        onClick={item.onClick}
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 pr-[6vw] py-2 text-sm text-gray-700 mx-auto',
                        )}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          {/* Help button */}
          <div className="flex flex-shrink-0 bg-white p-4">
            <button type="button">
              <div className="flex flex-row items-center">
                <a href={tangoUrl} target="_blank" rel="noreferrer">
                  <QuestionMarkCircleIcon className="h-8" />
                </a>
                <div className="ml-2 text-gray-600 hidden xl:block">Help</div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className={`-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500
            hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
