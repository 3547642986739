import { DataSource } from '../landing-page/landing-page-types';

/**
 * Roles that can be assigned to users of the app.
 */
export const UserRoles = {
  ...DataSource,
  ADMIN: 'Admin',
} as const;

// Type of UserRoles
export type UserRole = typeof UserRoles[keyof typeof UserRoles];

/**
 * Represents an app user.
 */
export interface UserRecord {
  uid: string;
  email?: string;
  emailVerified: boolean;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
  disabled: boolean;
  customClaims?: { [key: string]: any; };
  tenantId?: string | null;
}
