import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import cronstrue from 'cronstrue';
import { IconButton } from '@mui/material';
import { Launch, Sync } from '@mui/icons-material';
import { EntrataTableData, TableScheduleType } from './entrata-sync-types';
import { CustomScheduleActions } from './CustomScheduleActions';
import { EntrataManualSync } from './EntrataManualSync';
import { EntrataTablesGroup, TableGroupDoc, TableGroupMapping } from '../landing-page-types';
import { UpdateGroupSelector } from '../UpdateGroupSelector';
import { EntrataTablesDatagridParams } from './entrata-sync-params';

/**
 * Given a table in the `database_raw` dataset in BigQuery, generates a URL to that table.
 * @param table table to link to
 */
// eslint-disable-next-line max-len
const generateBqLink = (table: string) => `https://console.cloud.google.com/bigquery?project=wreg-entrata&supportedpurview=project&ws=!1m5!1m4!4m3!1swreg-entrata!2sdatabase_raw!3s${table}`;

/**
 * Generates columns for tables/sync jobs DataGrid.
 * @param setTableIdBeingEdited function to set which table is having its custom scheduler edited
 * @param setTableScheduleBeingDeleted function to set which table is having its custom scheduler deleted
 * @param currentGroup the parent group of this DataGrid
 * @param groups all groups
 * @param groupMappings all group mappings
 * @param refreshTable function to refresh table
 */
const getColumns = (
  setTableIdBeingEdited: (table: string) => void,
  setTableScheduleBeingDeleted: (table: string) => void,
  currentGroup: EntrataTablesGroup,
  groups: TableGroupDoc[],
  groupMappings: TableGroupMapping[],
  refreshTable: () => void,
): GridColDef<EntrataTableData>[] => ([
  {
    headerName: 'Table Name',
    field: 'table',
    flex: 1,
  },
  {
    headerName: 'Table Last Updated',
    field: 'lastModifiedTime',
    flex: 1,
    valueGetter: ({ row }) => new Date(row.lastModifiedTime).toLocaleString(),
  },
  {
    headerName: 'Schedule',
    field: 'schedule',
    flex: 1,
    cellClassName: (params) => (params.row.schedule.type === TableScheduleType.CUSTOM ? 'bg-blue-100' : ''),
    valueGetter: ({ row }) => {
      const { schedule, timeZone } = row.schedule.schedule;
      const scheduleString = cronstrue.toString(schedule);
      return `${scheduleString} (${timeZone})`;
    },
  },
  {
    headerName: 'Schedule Actions',
    field: 'customActions',
    width: 150,
    cellClassName: (params) => (params.row.schedule.type === TableScheduleType.CUSTOM ? 'bg-blue-100' : ''),
    renderCell: ({ row }) => (
      <CustomScheduleActions
        row={row}
        setTableIdBeingEdited={setTableIdBeingEdited}
        setTableScheduleBeingDeleted={setTableScheduleBeingDeleted}
      />
    ),
  },
  {
    headerName: 'View in BigQuery',
    field: 'link',
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (
      <IconButton
        onClick={() => window.open(generateBqLink(row.table))}
        className="mx-auto text-blue-500"
      >
        <Launch />
      </IconButton>
    ),
  },
  {
    headerName: 'Syncing Now?',
    field: 'inQueue',
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => (row.inQueue && <Sync className="text-green-500 mx-auto" />),
  },
  {
    headerName: 'Manual Sync',
    field: 'manualSync',
    width: 150,
    headerAlign: 'center',
    renderCell: ({ row }) => <EntrataManualSync table={row.table} />,
  },
  {
    headerName: 'Group',
    field: 'group',
    width: 150,
    renderCell: ({ row }) => (
      <UpdateGroupSelector
        tableId={row.table}
        refreshTable={refreshTable}
        groupMappings={groupMappings}
        groups={groups}
        currentGroupId={currentGroup.groupId}
      />
    ),
  },
]);

/**
 * Entrata tables datagrid - shows all tables in a given group.
 * @param group tables group
 * @param groups all groups
 * @param groupMappings group mappings of which table is in which group
 * @param setTableIdBeingEdited set which table's schedule is being edited
 * @param setTableScheduleBeingDeleted set which table's schedule is being deleted
 * @param refreshTable refresh the underlying table
 * @constructor
 */
export const EntrataTablesDataGrid = function ({
  group,
  groups,
  groupMappings,
  setTableIdBeingEdited,
  setTableScheduleBeingDeleted,
  refreshTable,
}: EntrataTablesDatagridParams) {
  const columns = getColumns(
    setTableIdBeingEdited,
    setTableScheduleBeingDeleted,
    group,
    groups,
    groupMappings,
    refreshTable,
  );

  return (
    <DataGridPro
      rows={group.tables}
      columns={columns}
      getRowId={({ table }) => table}
      disableRowSelectionOnClick
    />
  );
};
