import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Add, CreateNewFolderOutlined } from '@mui/icons-material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ManageJobModal } from './manage-job-modal/ManageJobModal';
import { DeleteJobModal } from './DeleteJobModal';
import {
  JobRunHistory, SheetsTablesGroup, SheetSyncJob, TableGroupDoc, TableGroupMapping, TablesGroup, UpdateGroupInfo,
} from '../landing-page-types';
import { getJobs } from '../../../api/endpoints-api/EndpointsAPI';
import { filterJobsForUser, watchJobRunHistory } from '../../../api/landing-page-api/LandingPageAPI';
import { alertError } from '../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { useUser } from '../../../api';
import { getGroupColumns, getGroupRows } from '../landing-page-helpers';
import { JobsDataGrid } from './jobs-datagrid/JobsDataGrid';

// Params for GoogleSheetsSync component.
interface GoogleSheetsSyncParams {
  groups: TableGroupDoc[],
  groupMappings: TableGroupMapping[]
  loadingGroups: boolean
  openCreateGroupModal: () => void
  refreshTable: () => void
  updateGroupToDelete: (group: TablesGroup) => void
}

/**
 * Component to manage sync jobs between Google Sheets and BigQuery.
 * @constructor
 */
export const GoogleSheetsSync = function ({
  groups, groupMappings, loadingGroups, openCreateGroupModal, refreshTable, updateGroupToDelete,
}: GoogleSheetsSyncParams) {
  const [createJobModalOpen, setCreateJobModalOpen] = useState<boolean>(false);
  // id of a job being deleted - dictates whether delete modal is open
  const [deleteModalJobId, setDeleteModalJobId] = useState<string>('');
  // id of a job being edited - dictates whether edit modal is open
  const [editJobModalId, setEditJobModalId] = useState<string>('');
  // jobs rows which populate the jobs Datagrid
  const [jobRows, setJobRows] = useState<SheetSyncJob[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);
  // history of all job runs
  const [jobRunHistory, setJobRunHistory] = useState<JobRunHistory[]>([]);

  const alert = useAlert();
  const user = useUser();

  // retrieves all jobs viewable to the current user
  useEffect(() => {
    if (!user) return;
    getJobs()
      .then(({ data }: AxiosResponse<SheetSyncJob[]>) => {
        // filter jobs for relevant jobs
        const rows = filterJobsForUser(data, user);
        setJobRows(rows);
      })
      .catch((err) => { throw alertError(alert, 'Failed to pull jobs', err); })
      .finally(() => setJobsLoading(false));
  }, [createJobModalOpen, deleteModalJobId, editJobModalId]);

  // Gets real-time jobRun data updates from Firestore
  useEffect(() => {
    const unsubscribe = watchJobRunHistory(setJobRunHistory);
    return () => unsubscribe();
  }, []);

  const updateDeleteModalJobId = (id: string) => setDeleteModalJobId(id);
  const updateEditModalJobId = (id: string) => setEditJobModalId(id);
  // job which is being edited (if there is one)
  const jobBeingEdited = useMemo(
    () => jobRows.find(({ jobId }) => jobId === editJobModalId),
    [jobRows, editJobModalId],
  );
    // job which is being deleted (if there is one)
  const jobBeingDeleted = useMemo(
    () => jobRows.find(({ jobId }) => jobId === deleteModalJobId),
    [jobRows, deleteModalJobId],
  );

  // Actions for speed dial component
  const speedDialActions = [
    { icon: <Add />, name: 'Sync new sheet', action: () => setCreateJobModalOpen(true) },
    { icon: <CreateNewFolderOutlined />, name: 'Create new group', action: openCreateGroupModal },
  ];

  // Generate rows for datagrid
  const rows: SheetsTablesGroup[] = useMemo(() => getGroupRows<SheetSyncJob>(
    groupMappings,
    groups,
    jobRows,
    ({ jobId }) => jobId,
  ), [groups, groupMappings, jobRows]);

  // Generate columns for Datagrid
  const columns = useMemo(
    () => getGroupColumns<SheetSyncJob>(updateGroupToDelete, groups, refreshTable),
    [updateGroupToDelete, groups, refreshTable],
  );

  return (
    <>

      {/*  Jobs datagrid */}
      <div className="h-[90%]">
        <DataGridPro
          rows={rows}
          columns={columns}
          loading={jobsLoading || loadingGroups}
          getRowId={({ groupName }) => groupName}
          getDetailPanelContent={({ row }) => {
            const updateGroupInfo: UpdateGroupInfo = {
              groupMappings, groups, currentGroupId: row.groupId, refreshTable,
            };
            return JobsDataGrid({
              jobRows: row.tables,
              updateDeleteModalJobId,
              updateEditModalJobId,
              interactive: true,
              jobsLoading,
              jobRunHistory,
              updateGroupInfo,
            });
          }}
          getDetailPanelHeight={() => 'auto'}
          disableRowSelectionOnClick
        />
      </div>
      {/* Create new job modal */}
      {createJobModalOpen && (
      <ManageJobModal
        modalOpen={createJobModalOpen}
        closeModal={() => setCreateJobModalOpen(false)}
        jobs={jobRows}
      />
      )}
      {/* Edit job modal */}
      {editJobModalId && jobBeingEdited && (
      <ManageJobModal
        modalOpen={!!editJobModalId}
        closeModal={() => setEditJobModalId('')}
        existingSyncJob={jobBeingEdited}
        jobs={jobRows}
      />
      )}
      {/* Delete job modal */}
      {deleteModalJobId && jobBeingDeleted && (
      <DeleteJobModal
        deleteModalJobId={deleteModalJobId}
        updateDeleteModalJobId={updateDeleteModalJobId}
        jobBeingDeleted={jobBeingDeleted}
      />
      )}
      {/*  Sync new sheet button */}
      <div className="h-[10%] pt-4 flex flex-row justify-end">
        <SpeedDial
          ariaLabel="Actions"
          icon={<SpeedDialIcon />}
          direction="left"
          FabProps={{ sx: { backgroundColor: 'rgb(59 130 246) !important' } }}
        >
          {speedDialActions.map(({ name, icon, action }) => (
            <SpeedDialAction
              key={name}
              icon={icon}
              tooltipTitle={name}
              onClick={action}
            />
          ))}
        </SpeedDial>
      </div>
    </>
  );
};
