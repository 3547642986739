/**
 * Represents a Cloud Scheduler for an Entrata table sync job.
 */
interface EntrataTableScheduler {
  name: string,
  schedule: string,
  timeZone: string
}

/**
 * Represents different types of table schedule.
 */
export enum TableScheduleType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

/**
 * Represents metadata related to an Entrata Table sync job.
 */
export interface EntrataTableData {
  table: string,
  lastModifiedTime: number
  inQueue: boolean
  schedule: {
    schedule: EntrataTableScheduler,
    type: TableScheduleType
  }
}

/**
 * Represents different types of schedules for Entrata sync jobs.
 */
export enum EntrataSchedules {
  EVERY_HOUR_EVERY_DAY = 'Every hour 24/7',
  EVERY_HOUR_WEEKDAYS = 'Every hour weekdays',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}
