import {
  BuildingOfficeIcon, Cog6ToothIcon, TableCellsIcon,
} from '@heroicons/react/24/outline';
import history from '../history/History';
import { NavigationItem, PageType } from './NavigationTypes';
import { UserRoles } from '../../pages/admin-page/admin-types';

/**
 * A helper method to provide navigation to endpoints.
 * @param location: string
 * @param absolute: boolean Use absolute path
 * @param state: optional state
 */
export const navigateTo = (location: string, absolute?:boolean, state?: Record<string, unknown>) => {
  history.push(absolute ? `/${location}` : location, state);
};

/**
 * Serves as the external definition for the navigation paths.
 * While this could be placed inside a component directly,
 * it felt clearer to store it within the navigation API
 */
export const getNavigationItems = (): NavigationItem[] => [
  {
    id: PageType.GoogleSheets,
    href: '/sheets',
    name: UserRoles.GOOGLE_SHEETS,
    adminRestricted: false,
    icon: TableCellsIcon,
  },
  {
    id: PageType.Entrata,
    href: '/entrata',
    name: UserRoles.ENTRATA,
    adminRestricted: false,
    icon: BuildingOfficeIcon,
  },
  {
    id: PageType.Admin,
    href: '/admin',
    name: UserRoles.ADMIN,
    adminRestricted: true,
    icon: Cog6ToothIcon,
  },
];
