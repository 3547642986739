import { Sync } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { alertError } from '../../../../api/error-api/ErrorAPI';
import { useAlert } from '../../../../api/alert-api/AlertAPI';
import { manualSync } from '../../../../api/endpoints-api/EndpointsAPI';
import { useUser } from '../../../../api';
import { ManualSyncReq } from '../../landing-page-types';

export const ManualSyncButton = function (jobId: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();

  const user = useUser();
  const performManualSync = () => {
    if (!user || !user.email) return;
    setLoading(true);
    const syncReq: ManualSyncReq = { jobId, userEmail: user.email };
    manualSync(syncReq)
      .then(() => {
        alert?.alert('Began manual sync', 'success');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throw alertError(alert, 'Failed to begin manual sync', err);
      });
  };

  return (
    <IconButton
      className="bg-blue-500 hover:bg-blue-400 mx-auto"
      onClick={performManualSync}
    >
      {/*  Display loading icon when awaiting api call success */}
      {loading
        ? (<CircularProgress size="1.5rem" className="text-white" />)
        : (<Sync className="text-white" />)}
    </IconButton>
  );
};
