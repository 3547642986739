import React, { useState } from 'react';
import { Checkbox, CircularProgress } from '@mui/material';
import { UserRecord, UserRole } from './admin-types';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { updateAppUserRole } from '../../api/endpoints-api/EndpointsAPI';

// Params for UserRoleCheckbox component
interface UserRoleCheckboxParams {
  user: UserRecord,
  role: UserRole,
  toggleRefresh: () => void
}

/**
 * Checkbox that can be used to update user role.
 * @param user app user
 * @param role the role we are updating
 * @param toggleRefresh function to refresh the users table
 * @constructor
 */
export const UserRoleCheckbox = function ({ user, role, toggleRefresh }: UserRoleCheckboxParams) {
  const [loading, setLoading] = useState<boolean>(false);

  // the initial value of the checkbox should reflect whether the user has been assigned the given role
  const roles = user.customClaims?.roles as UserRole[] | undefined;
  const hasRole = !!roles?.includes(role);
  const [checked, setChecked] = useState<boolean>(hasRole);

  const alert = useAlert();

  /**
   * Adds or removes the given role from the user (basically just inverts the current value).
   */
  const toggleRole = () => {
    setLoading(true);
    updateAppUserRole({ uid: user.uid, role, addRole: !checked })
      .then(() => {
        alert?.alert('Successfully updated role', 'success');
        // we change this state to immediately visually reflect our expected change since the refresh toggle takes a second
        setChecked(!checked);
        toggleRefresh();
      })
      .catch(() => { alert?.alert('Failed to update role', 'error'); })
      .finally(() => { setLoading(false); });
  };

  if (loading) return <CircularProgress className="p-2" />;
  return <Checkbox checked={checked} onChange={toggleRole} className="mx-auto" />;
};
