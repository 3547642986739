import React, { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import { Sync } from '@mui/icons-material';
import { AxiosError, AxiosResponse } from 'axios';
import { useAlert } from '../../../api/alert-api/AlertAPI';
import { manualEntrataSync } from '../../../api/endpoints-api/EndpointsAPI';

/**
 * Manual sync button for a given Entrata table sync job.
 * @param table the table that the button syncs
 * @constructor
 */
export const EntrataManualSync = function ({ table }:{ table: string }) {
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  /**
   * Triggers manual sync job for a given Entrata table.
   */
  const manualSync = () => {
    setLoading(true);
    manualEntrataSync(table)
      .then(() => alert?.alert('Successfully started sync', 'success'))
      .catch((err: AxiosError<string>) => {
        const errMsg = `Failed to start sync: ${err?.response?.data || 'Unknown error'}`;
        alert?.alert(errMsg, 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <IconButton
      className="bg-blue-500 hover:bg-blue-400 mx-auto"
      onClick={manualSync}
    >
      {loading
        ? (<CircularProgress size="1.5rem" className="text-white" />)
        : (<Sync className="text-white" />)}
    </IconButton>
  );
};
