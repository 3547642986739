import React, { useEffect, useState } from 'react';
import { SheetSyncContainer } from '../../helper';
import { GoogleSheetsSync } from './google-sheet-sync/GoogleSheetsSync';
import { EntrataSync } from './entrata-sync/EntrataSync';
import {
  DataSource, TableGroupDoc, TableGroupMapping, TablesGroup,
} from './landing-page-types';
import { UserRole } from '../admin-page/admin-types';
import { getNavigationItems } from '../../api';
import { getGroupsData } from './landing-page-helpers';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { CreateGroupModal } from './CreateGroupModal';
import { DeleteGroupConfirmModal } from './DeleteGroupConfirmModal';

/**
 * Params for LandingPage component.
 */
interface LandingPageParams {
  userRoles: UserRole[] | undefined | null,
  source?: DataSource
}

/**
 * The main landing page of the application, which displays tabs for different data sync sources.
 * @constructor
 */
export const LandingPage = function ({
  userRoles,
  source,
}: LandingPageParams) {
  // sync job groups that are assigned to the current datasource
  const [groups, setGroups] = useState<TableGroupDoc[]>([]);
  // mappings of jobs/tables to groups
  const [groupMappings, setGroupMappings] = useState<TableGroupMapping[]>([]);
  const [loadingGroups, setLoadingGroups] = useState<boolean>(true);
  const [createGroupModalOpen, setCreateGroupModalOpen] = useState<boolean>(false);
  // refreshes the datagrid by re-pulling relevant data
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  // the group that the user is trying to delete (controls delete group modal)
  const [groupToDelete, setGroupToDelete] = useState<TablesGroup | null>(null);

  const refreshTable = () => setToggleRefresh(!toggleRefresh);
  const updateGroupToDelete = (group: TablesGroup) => setGroupToDelete(group);

  // the current focused page - this controls the navbar focus
  const focusedPage = getNavigationItems().find(({ name }) => name === source)?.id;

  const alert = useAlert();

  /**
   * Retrieves groups data relevant to the current data source.
   */
  useEffect(() => {
    // refresh data if closing the create group modal, but not when opening it
    if (createGroupModalOpen || !source) return;
    setLoadingGroups(true);
    getGroupsData(source)
      .then(({ groupDocs, mappings }) => {
        setGroups(groupDocs);
        setGroupMappings(mappings);
      })
      .catch(() => { alert?.alert('Failed to retrieve groups data', 'error'); })
      .finally(() => { setLoadingGroups(false); });
  }, [createGroupModalOpen, toggleRefresh, source]);

  // The different tabs/data sources mapped to their components
  const sourceTabs = {
    [DataSource.GOOGLE_SHEETS]: <GoogleSheetsSync
      groups={groups}
      groupMappings={groupMappings}
      loadingGroups={loadingGroups}
      openCreateGroupModal={() => setCreateGroupModalOpen(true)}
      updateGroupToDelete={updateGroupToDelete}
      refreshTable={refreshTable}
    />,
    [DataSource.ENTRATA]: <EntrataSync
      groups={groups}
      groupMappings={groupMappings}
      loadingGroups={loadingGroups}
      openCreateGroupModal={() => setCreateGroupModalOpen(true)}
      updateGroupToDelete={updateGroupToDelete}
      refreshTable={refreshTable}
      toggleRefresh={toggleRefresh}
    />,
  };

  return (
    <SheetSyncContainer
      focusedPage={focusedPage}
      className="bg-gray-50 w-[100vw]"
      userRoles={userRoles}
    >
      {/* Default message which shows if a data source hasn't been selected.  If so, user probably lacks permissions. */}
      {!source && (<div className="ml-96">You must request permissions to use this app.</div>)}
      {/* If a data source is selected, render the relevant component. */}
      {source && (
      <div className="ml-44 font-inter flex flex-col h-[100vh] p-6">
        <div className="text-5xl font-semibold h-[10%]">Table Sync</div>
        <div className="h-[90%]">
          {/* Modal to create group */}
          {createGroupModalOpen && (
          <CreateGroupModal
            closeModal={() => setCreateGroupModalOpen(false)}
            source={source}
          />
          )}
          {/* Confirmation modal to delete group */}
          {groupToDelete && (
          <DeleteGroupConfirmModal
            group={groupToDelete}
            refreshTable={refreshTable}
            closeModal={() => setGroupToDelete(null)}
          />
          )}
          { sourceTabs[source] }
        </div>
      </div>
      )}
    </SheetSyncContainer>
  );
};
