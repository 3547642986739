import {
  FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, Switch, Tooltip, Typography,
} from '@mui/material';
import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { WriteDispositionSelectParams } from '../../landing-page-params';

export const WriteDispositionSelect = function ({
  syncJob,
  updateSyncJob,
  previewRows,
  existingSyncJob,
  loadingSheetData,
  userDoesNotHaveSheetAccess,
}: WriteDispositionSelectParams) {
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-8 mt-6" />;

  const identifierColText = 'Optional - select an identifier column if you would like only changes made to the sheet to'
      + ' be appended to the table.  If no identifier column is selected, the entire sheet will be appended each sync. '
      + ' Every value in an identifier column must be unique.';

  const { identifierColumn, overwriteOnSync } = syncJob;

  /**
   * We get our headers by taking the keys of the first row.  Technically this works (despite empty cells resulting in
   * no header (key) being present in a given row (object)), because we are using these headers to select an identifier column, and if there
   * is an empty cell in an identifier column then that column can't be used anyway as all values in the column must be unique.
   * So to some extent, this naturally filters out invalid column options.
   */
  const columnOptions = previewRows.length > 0 ? Object.keys(previewRows[0]) : [identifierColumn];

  return (
    <>
      {/* Write disposition toggle */}
      <Stack direction="row" spacing={1} alignItems="center" className="mt-6">
        <Typography>Append On Sync</Typography>
        <Switch
          checked={overwriteOnSync}
          onChange={({ target }) => updateSyncJob({ ...syncJob, overwriteOnSync: target.checked })}
        />
        <Typography>Overwrite On Sync</Typography>
      </Stack>
      {/* Identifier column select (if appending) */}
      {!overwriteOnSync && columnOptions.length > 0 && (
      <div className="flex flex-row items-center mt-6">
        <FormControl className="w-full">
          <InputLabel>Identifier Column</InputLabel>
          <Select
            value={identifierColumn}
            label="Identifier Column"
            onChange={({ target }) => updateSyncJob({ ...syncJob, identifierColumn: target.value })}
            // if a user is editing a job but the job already has a set ID column OR if they do not have access to the underlying
            // sheet of the job, they should not be able to edit the job's ID column
            disabled={!!existingSyncJob?.uuidColumn || userDoesNotHaveSheetAccess}
          >
            {columnOptions.map((header) => (
              <MenuItem key={header} value={header}>{header}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Tooltip which explains the purpose of identifier column */}
        <Tooltip title={identifierColText} className="w-8 ml-2">
          <InfoOutlined className="text-gray-400" />
        </Tooltip>
      </div>
      )}
    </>
  );
};
