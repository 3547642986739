import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EntrataDeleteConfirmModalParams } from './entrata-sync-params';
import { deleteEntrataScheduler } from '../../../api/endpoints-api/EndpointsAPI';
import { useAlert } from '../../../api/alert-api/AlertAPI';

/**
 * Confirmation modal that pops up when trying to delete a custom schedule.
 * @param tableName table name for custom schedule
 * @param closeModal function to close this modal
 * @param refreshTable function to refresh the dataGrid
 * @constructor
 */
export const EntrataDeleteConfirmModal = function ({
  tableName,
  closeModal,
  refreshTable,
}: EntrataDeleteConfirmModalParams) {
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const alert = useAlert();

  /**
     * Deletes the custom Cloud Scheduler from GCP for a given sync job.
     */
  const deleteSchedule = () => {
    setLoadingDelete(true);
    deleteEntrataScheduler({ tableName })
      .then(() => {
        alert?.alert('Deleted schedule successfully', 'success');
        closeModal();
        refreshTable();
      })
      .catch(() => { alert?.alert('Failed to delete schedule', 'error'); })
      .finally(() => { setLoadingDelete(false); });
  };

  return (
    <Dialog
      open
      onClose={closeModal}
      className="w-[100vw] bg-transparent"
      maxWidth="xl"
    >
      {/* Modal itself  */}
      <div className="relative z-40 p-10 bg-white rounded-lg flex flex-col gap-y-8
        items-center justify-center"
      >
        <div>
          Delete custom schedule for &nbsp;
          <b>
            {tableName}
          </b>
          ?
        </div>
        <LoadingButton
          color="error"
          onClick={deleteSchedule}
          className="mx-auto bg-red-500"
          loading={loadingDelete}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </div>
    </Dialog>
  );
};
